export default {
  namespaced: true,
  state: {
    data: JSON.parse(localStorage.getItem('storeData')),
    isUrlReady: true,
    release: {
      version: localStorage.getItem('store.release.version') || '1.0',
    },
  },
  getters: {},
  mutations: {
    UPDATE_STORE_DATA(state, obj) {
      state.data = obj
      state.release.version = obj.version || '1.0'

      localStorage.setItem('storeId', state.data.id)
      localStorage.setItem('storeData', JSON.stringify(state.data))
      localStorage.setItem('store.release.version', state.release.version)
      localStorage.setItem('store.currency', state.data.currency)
      localStorage.setItem('store.media', state.data.media_path)
    },
    UPDATE_VERSION(state, val) {
      state.release.version = val
      localStorage.setItem('store.release.version', val)
    },
    UPDATE_URL_READY(state, val) {
      state.isUrlReady = val
    },
  },
  actions: {},
}
